html {
    height:100%;
}

body {
    min-height:100%;
}

a {
    color: #f8be38;
    cursor: pointer;
    text-decoration: none;
}

a:hover {
    color: #44a4bb;
    text-decoration: none;
    text-shadow: 1px 1px #222222;
}

h2 {
    margin: 5px;
}

.nav-button {
    color: #ffffff;
    height: 50px;
    text-shadow: 2px 2px 3px #222222;
    width: 100px;
}

.nav-button:hover {
    color: #f8be38;
    text-shadow: 2px 2px #222222;
}

.news-post {
    margin: 2px 10px 2px 10px;
    padding: 5px 5px 5px 5px;
    margin: 2px 10px 2px 10px;
    text-overflow: ellipsis;
}

.news-post:hover {
    color: #cccccc;
    margin: 2px 10px 2px 10px;
    padding: 5px 5px 5px 5px;
    margin: 2px 10px 2px 10px;
    text-overflow: ellipsis;
}

.news-content {
    margin: 2px 10px 2px 10px;
    padding: 15px 5px 15px 5px;
}

.news-content img {
    margin: 0 auto;
    padding: 5px;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}